import { AddRounded, RemoveRounded } from "@mui/icons-material";
import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CtaButton from "../components/CtaButton/CtaButton";
import FeedPost from "../components/FeedPost/FeedPost";
import NotFound from "./NotFound";
import { UserContext } from "../hooks/UserContext";
import useAuth from "../hooks/useAuth";

const API_URL = process.env.REACT_APP_API_URL;

export default function Forum() {

    const navigate = useNavigate();
    const { requireLogin } = useAuth();
    const { id }  = useParams();

    const { favForums, refreshFavForums } = useContext(UserContext);

    const [ forumInfo, setForumInfo ] = useState(null);
    const [ isForumLoading, setForumLoading ] = useState(true);
    const [ statusIs404, setStatusIs404 ] = useState(null);
    // Find a way to do this without rerendering the whole page
    const [ postArray, setPostArray ] = useState(null);

    const lastTimestamp = useRef(undefined);
    const loadingNextPage = useRef(true); // Initially True while loading first page
    const [ reachedEnd, setReachedEnd ] = useState(false);

    const isInForum = favForums.some(forumId => forumId === id);

    useEffect(() => {
        const getNextPosts = async () => {
            loadingNextPage.current = true;

            try {
                const response = await axios.get(API_URL + "/post/fromForum/" + id, { params: {start: lastTimestamp.current} });
                console.log(response.data);
                if (response.data.length === 0) {
                    setReachedEnd(true);
                }
                else {
                    lastTimestamp.current = response.data[response.data.length-1].createdAt;
                    setPostArray(posts => posts.concat(response.data));
                }
            }
            catch (error) {
                console.log(error);
            }
            loadingNextPage.current = false;
        }

        const handleScroll = (event) => {
            const offsetHeight = document.documentElement.offsetHeight;
            const innerHeight = window.innerHeight;
            const scrollTop = document.documentElement.scrollTop;

            const hasReachedBottom = offsetHeight - (innerHeight + scrollTop) <= 300;

            if (hasReachedBottom && !loadingNextPage.current && !reachedEnd) {
                console.log("Fetching next posts");
                getNextPosts();
            }
        }

        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll)
    }, [id, reachedEnd]);

    const handleJoinButton = (e) => {
        e.preventDefault();
        if (requireLogin()) {
            const button = document.getElementById("Join-button");
            button.setAttribute("disabled", true);

            const url = API_URL + "/user/" + (isInForum ? "remFavForum" : "addFavForum");
            axios.post(url, {forumId: id}).then(() => {
                button.removeAttribute("disabled");
                refreshFavForums();
            }).catch((err) => {
                console.log(err)
                button.removeAttribute("disabled");
            })
        }
    }

    // Get forum info and posts from the backend
    useEffect(() => {
        // Prevents race conditions by preventing old network
        // requests from updating the view if the user is rapidly switching forums
        const cancelToken = axios.CancelToken.source();

        setStatusIs404(false);
        setForumLoading(true); // Force the sidebar loading indicator to display when switching forums
        setReachedEnd(false);

        axios.get(API_URL + "/forum/" + id, { cancelToken: cancelToken.token }).then((res) => {
                setForumInfo(res.data);
                setForumLoading(false);
        }).catch((err) => {
            console.log(err);
            if (err.response.status && !axios.isCancel(err)) {
                setStatusIs404(true);
            }
        })

        axios.get(API_URL + "/post/fromForum/" + id, { cancelToken: cancelToken.token }).then((response) => {
            lastTimestamp.current = response.data[response.data.length-1].createdAt;
            loadingNextPage.current = false;
            setPostArray(response.data);
        }).catch((err) => {
            console.log(err);
            if (!axios.isCancel(err)) { setPostArray([]); }
        });

        // If rapidly switching between forums, discard old network requests
        return () => { cancelToken.cancel() };
    }, [id]);

    var posts = postArray?.map(function(post){
        return <FeedPost userId={post.userID} post={post} forum={forumInfo} key={post._id} />;
    });

    // 404 Error page if there is no forum with the given ID
    if (statusIs404) {
        return <NotFound subject="forum"/>;
    }

    return (
        <main>
            <div className='Feed'>
                <div className='Feed-header'>
                    <button className='Feed-header-button Selected' onClick={() => {/* Set sorting method, refreshes feed */}}>Latest</button>
                    <button className='Feed-header-button' disabled={true} onClick={() => {/* Set sorting method, refreshes feed */}}>Popular</button>
                    <div className='Spacer'/>
                    <CtaButton onClick={() => {navigate('/createPost?forum=' + (forumInfo?._id || "") )}} text="Create Post" icon={<AddRounded/>}/>
                </div>
                <div id="Posts-container">
                    { postArray ?
                        posts
                        : null
                    }
                    { (postArray && postArray.length === 0) ? <><br/><h3 className="Center-text">{"Nobody has posted in " + forumInfo.name + " yet"}</h3></> : null }
                    { reachedEnd && <h3 className="End-notice">You've reached the end!</h3>}
                </div>

            </div>
            <div className='Sidebar'>
            { !isForumLoading ? // Show a placeholder until forum data is fetched
                <>
                    <div className="Sidebar-banner-container">
                        <img className="Sidebar-banner" src={forumInfo.forumImage} alt="" onError={(error) => {error.target.src = "/placeholder-large.svg"}}/>
                    </div>
                    <h2>{forumInfo.name}</h2>
                    <div className="Separator"></div>
                    <p>{forumInfo.bio}</p>
                    <CtaButton small id="Join-button"
                        outlined={isInForum}
                        text={ isInForum ? "Leave Forum" : "Join Forum" }
                        icon={ isInForum ? <RemoveRounded/> : <AddRounded/> }
                        onClick={handleJoinButton}
                        />
                </>
                :
                <>
                    <div className="Sidebar-banner-container">
                        <div className="Sidebar-banner Loading"/>
                    </div>
                    <div className="Loading-header"/>
                </>
            }
            </div>
        </main>
    );

}