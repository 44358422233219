
export default function NotFound({ subject }) {
    subject ??= "page";

    return (
        <main>
            <div className='Feed Pad-top'>
                <br/>
                <h2 className="Center-text">We couldn't find the {subject} you're looking for</h2>
                <p className="Center-text">Try checking that the URL was entered correctly.</p>
                <br/>
            </div>
            <div className='Sidebar'>
                <h2>Page not found :(</h2>
            </div>
        </main>
    );
}