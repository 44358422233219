import './App.css';
import { UserContext } from "./hooks/UserContext";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ProtectedRoute from "./components/ProtectedRoute";

import Home from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/Signup';
import NotFound from './pages/NotFound';
import useFindUser from './hooks/useFindUser';
import NavBar from "./components/Navbar/NavBar";
import SigninRoute from './components/SigninRoute';
import Settings from './pages/Settings';
import User from './pages/User';
import Post from './pages/Post';
import Forum from './pages/Forum';
import CreatePost from './pages/CreatePost';
import CreateForum from './pages/CreateForum';
import ForumList from './pages/ForumList';

export default function App() {

  const { user, setUser, isLoading, refreshUser, favForums, setFavForums, refreshFavForums } = useFindUser();

  return (
    <BrowserRouter>
      <UserContext.Provider value={{ user, setUser, isLoading, refreshUser, favForums, setFavForums, refreshFavForums }}>
        <div className="App">
          <NavBar/>
          <Routes>
            <Route exact path="/createPost" element={
              <ProtectedRoute element={<CreatePost user = {user}/>}/>
            }/>
            <Route exact path="/createForum" element={
              <ProtectedRoute element={<CreateForum/>}/>
            }/>
            <Route exact path="/" element={
              <Home/>
            }/>
            <Route exact path="/user/:id" element={
              <User/>
            }/>
            <Route exact path="/post/:id" element={
              <Post/>
            }/>
            <Route exact path="/forum/:id" element={
              <Forum/>
            }/>
            <Route exact path="/forums" element={
              <ForumList/>
            }/>
            <Route exact path="/settings" element={
              <ProtectedRoute element={<Settings/>}/>
            }/>
            <Route exact path="/login" element={<SigninRoute element={<Login/>}/>}/>
            <Route exact path="/signup" element={<SigninRoute element={<Signup/>}/>}/>
            <Route exact path="*" element={<NotFound/>}/>
          </Routes>
        </div>
      </UserContext.Provider>
    </BrowserRouter>
  );

}
