import { Link, useNavigate } from "react-router-dom"
import ReactTimeAgo from "react-time-ago";
import { VisibilityOutlined, ChatBubbleOutlineRounded, ArrowUpwardRounded, ArrowDownwardRounded } from "@mui/icons-material";
 import { useEffect, useState } from "react";
import './FeedPost.css'
import axios from "axios";
import Avatar from "../Avatar/Avatar";
import classNames from "classnames";

const API_URL = process.env.REACT_APP_API_URL;

export default function FeedPost({ userId, forumId, postId, user, forum, post }) {

    const [ forumInfo, setForumInfo ] = useState(forum ?? { });
    const [ userInfo, setUserInfo ] = useState(user ?? { });
    const [ postInfo, setPostInfo ] = useState(post ?? { });

    const [ vote, setVote ] = useState(null);

    let userProfileLink = "/user/" + (userId ?? postInfo.userID);
    let forumLink = "/forum/" + forumInfo._id;
    let postLink = "/post/" + postInfo._id;

    const navigate = useNavigate();

    // Effects are separated to prevent redundant network requests when just one prop changes

    // Post author information
    useEffect(() => {
        const cancelToken = axios.CancelToken.source();

        if (!user) {
            axios.get(API_URL + "/user/" + userId, { cancelToken: cancelToken.token })
                .then((result) => {
                    setUserInfo(result.data.data);
                }).catch((err) => {
                    // If the request failed (cancelation doesn't count) print it out
                    if (!axios.isCancel(err)) {console.log(err)}
                });
        }
        else if (!userInfo) {
            // Update the displayed info if the user info is passed after 
            // the component is first created
            setUserInfo(user);
        }

        // Cancel network requests if the user navigates away from the page
        return () => { cancelToken.cancel() };
    // Having userInfo in the dependency list causes this effect to run in a loop, 
    // repeatedly making extra requests for every post in the feed
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, user]);

    // Post information
    useEffect(() => {
        const cancelToken = axios.CancelToken.source();

        if (!post) {
            axios.get(API_URL + "/post/" + postId, { cancelToken: cancelToken.token })
                .then((result) => {
                    setPostInfo(result.data);
                }).catch((err) => {
                    if (!axios.isCancel(err)) {console.log(err)}
                });
        }

        return () => { cancelToken.cancel() };
    }, [postId, post]);

    // Vote check
    useEffect(() => {
        axios.get(API_URL + "/vote/hasVoted/" + (postId || postInfo._id))
            .then((result) => {
                if (result.data.hasVoted) {
                    setVote(result.data.isLike ? "Liked" : "Disliked");
                }
            }).catch((err) => {
                console.log(err);
            });
    }, [postId, postInfo._id]);

    // Used to get forum name
    useEffect(() => {
        const cancelToken = axios.CancelToken.source();

        if (!forum) {
            axios.get(API_URL + "/forum/" + forumId, { cancelToken: cancelToken.token })
                .then((result) => {
                    setForumInfo(result.data);
                }).catch((err) => {
                    if (!axios.isCancel(err)) {console.log(err)}
                });
        }

        return () => { cancelToken.cancel() };
    }, [forumId, forum]);

    const clicked = async () => {
        navigate(postLink);
    }

    const stopPropagation = (e) => {
        e.stopPropagation();
    }

    const like = async () => {
        if (vote === "Liked") {
            removeLike();
            return;
        }
        
        await axios.get(API_URL + "/vote/like/" + (postInfo._id || postId)).then(res => {
            const info = postInfo;
            info.votes += res.data.voteChange;
            setPostInfo(info);
            setVote("Liked");
        })
    }

    const dislike = async () => {
        if (vote === "Disliked") {
            removeLike();
            return;
        }

        await axios.get(API_URL + "/vote/dislike/" + (postInfo._id || postId)).then(res => {
            const info = postInfo;
            info.votes += res.data.voteChange;
            setPostInfo(info);
            setVote("Disliked");
        })
    }

    const removeLike = async () => {
        await axios.get(API_URL + "/vote/removeLike/" + (postInfo._id || postId)).then(res => {
            const info = postInfo;
            info.votes += res.data.voteChange;
            setPostInfo(info);
            setVote(null);
        })
    }

    return (
        <div className="Feed-post" onClick={clicked}>
            <div className="Feed-post-wrap-container">
                <div className="Feed-post-left" onClick={stopPropagation}>
                    <Link to={userProfileLink}>
                        <Avatar userId={userInfo._id} className="Feed-post-avatar"/>
                    </Link>
                    <div className="Vote-container">
                        <div className="Vote-arrows">
                        <div className={classNames("Like-button", { Liked: vote === "Liked"})} onClick={like}><ArrowUpwardRounded/></div>
                        <div className={classNames("Like-button", { Disliked: vote === "Disliked"})}  onClick={dislike}><ArrowDownwardRounded/></div>
                        </div>
                        <p className={classNames("Vote-counter", { Liked: vote === "Liked"}, { Disliked: vote === "Disliked"})} >{postInfo.votes}</p>
                    </div>
                </div>
                <div className="Feed-post-center" onClick={stopPropagation}>
                    <Link to={postLink} className="No-underline">
                        <h3 className="Feed-post-title">{postInfo.title}</h3>
                    </Link>
                    <p className="Feed-post-author">
                        <Link to={userProfileLink}>{"By " + userInfo.userName}</Link> in <Link to={forumLink}>{forumInfo.name}</Link>
                    </p>
                    <p className="Feed-post-content">{postInfo.content}</p>
                </div>
            </div>
            <div className="Feed-post-stats" onClick={stopPropagation}>
                { postInfo.createdAt ?
                <>
                    <p className="Feed-post-stat"><VisibilityOutlined className="Stat-icon"/>{postInfo.views}</p>
                    <p className="Feed-post-stat"><ChatBubbleOutlineRounded className="Stat-icon"/>{postInfo.childCommentIDs.length}</p>
                    <ReactTimeAgo date={Date.parse(postInfo.createdAt)} locale="en-US" timeStyle="short-minute-now" className="Time-ago"/>
                </>
                    : null
                }
            </div>
        </div>
    );
}