import { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { UserContext } from "../../hooks/UserContext";
import logo from '../../logo512.png';
import useAuth from "../../hooks/useAuth";
import DropdownController from "../DropdownController/DropdownController";
import "./Navbar.css"
import { AddRounded, ArrowDropDownRounded, ListRounded, LogoutRounded, MenuRounded, PersonOutlineRounded, SettingsOutlined } from "@mui/icons-material";
import axios from "axios";
import Avatar from "../Avatar/Avatar";

const API_URL = process.env.REACT_APP_API_URL;

export default function NavBar() {

    const { user, isLoading, favForums } = useContext(UserContext);
    const { logoutUser } = useAuth();
    const location = useLocation();

    const [forumArray, setForumArray] = useState(null);

    useEffect(() => {
        if (!favForums) { return; }

        // Make a list of quick links to each of the user's joined forums
        const forumDataArray = [];
        Promise.all( favForums.map((id, index) => {
            return axios.get(API_URL + "/forum/" + id).then((response) => {
                forumDataArray[index] = response.data;
            }).catch((err) => {
                console.log(err);
                forumDataArray[index] = {name: "Error"};
            });
        })).then(() => {
            setForumArray(forumDataArray);
        });
    }, [favForums]);

    return (
        <nav className="Nav">
            <Link to="/" className="Site-title">
              <img src={logo} className="App-logo" alt="Q"/>
              <h1 className="Title">nect</h1>
            </Link>

            <div className="Double-gap-between-these-two-elements"/>

            <DropdownController className="Nav-dropdown-container"
                button={
                    <>
                        <div className="Nav-dropdown-button Flex-row">
                            <h3>Navigation</h3>
                            <ArrowDropDownRounded/>
                        </div>
                        <div className="Nav-dropdown-button-narrow"><MenuRounded style={{ fontSize: "28pt", display: "block", }}/></div>
                    </>
                }
                dropdown={
                    <div className="Nav-dropdown">
                            { user &&
                                <>
                                    {forumArray?.map((forum, index) => {
                                        return (<Link to={"/forum/" + (forum._id)} className="Nav-dropdown-item" key={forum._id}>
                                            <img className="Nav-dropdown-icon" src={forum.forumImage} alt="" onError={(error) => {error.target.src = "/placeholder-small.svg"}}/>
                                            {forum.name}
                                        </Link>);
                                    })
                                    || forumArray?.length === 0 || <div className="Nav-dropdown-item">Loading...</div>}
                                    <div className="Separator"/>
                                </>
                            }

                            <Link to="/forums" className="Nav-dropdown-item">
                                <ListRounded className="Profile-dropdown-icon"/>
                                All Forums
                            </Link>
                            <Link to="/createForum" className="Nav-dropdown-item">
                                <AddRounded className="Profile-dropdown-icon"/>
                                Create Forum
                            </Link>
                        </div>
                }
            />

            <div className="Spacer"/>

            {user
                ?
                <DropdownController className="Profile-dropdown-container"
                    button={
                        <div className="Profile-dropdown-button Flex-row">
                            <h3 className="Navbar-username">{user.userName}</h3>
                            <Avatar userId={user._id} isLoading={isLoading} className="Navbar-avatar"/>
                        </div>
                    }
                    dropdown={
                        <div className="Profile-dropdown">
                            <Link to={"/user/" + user._id} className="Profile-dropdown-item">
                                <PersonOutlineRounded className="Profile-dropdown-icon"/>
                                My Profile
                            </Link>
                            <Link to="/settings" className="Profile-dropdown-item">
                                <SettingsOutlined className="Profile-dropdown-icon"/>
                                Settings
                            </Link>
                            <div className="Separator"/>
                            <button onClick={logoutUser} className="Profile-dropdown-item">
                                <LogoutRounded className="Profile-dropdown-icon"/>
                                Logout
                            </button>
                        </div>
                    }
                />
                :
                <div className="Login-button-container">
                    { /* Return to the current page after signing in */}
                    <Link to="/login" state={{ from: location }} className="Join-link">Login</Link>
                    <Link to="/signup" state={{ from: location }} className="Join-link">Sign Up</Link>
                </div>
            }
        </nav>
    );
}