import axios from "axios";
import { useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "./UserContext";

const API_URL = process.env.REACT_APP_API_URL;

export default function useAuth() {
    const { user, setUser, setFavForums } = useContext(UserContext);
    const [error, setError] = useState(null);

    const navigate = useNavigate();
    const location = useLocation();

    // Set user in context then redirect to home
    const setUserContext = async (redirect) => {
        return await axios.get(API_URL + "/user/checkUser").then(res => {
            console.log("Logged in as " + res.data.user.userName);
            setUser(res.data.user);
            setFavForums(res.data.user?.forumFavs || []);
            if (redirect) {
                navigate(redirect);
            }
        }).catch((error) => {
            setUser(null);
            setFavForums([]);
        })
    };

    // Register user
    const registerUser = async (data, redirect) => {
        const { firstName, lastName, userName, email, password, program, gradDate, confirmPassword, stayLoggedIn} = data;

        // Remove any previous error messages to avoid confusion
        setError(null);

        if (password !== confirmPassword) {
            setError("Password confirmation does not match password.");
            return;
        }

        if (program === "select") {
            setError("Please select your program");
            return;
        }

        if (gradDate === "select") {
            setError("Please select your graduation year");
            return;
        }

        return axios.post(API_URL + "/user/signup", {
            firstName, lastName, userName, email, password, program, gradDate, stayLoggedIn
        }).then(async () => {
            await setUserContext(redirect);
        }).catch((error) => {
            if (error.response) {
                setError(error.response.data.message);
            }
            else {
                setError("Problem connecting to server! Please try again later.");
            }
        })
    };

    const logoutUser = async () => {
        return await axios.post(API_URL + "/user/logout").then(async () => {
            await setUserContext();
        }).catch((error) => {
            console.log(error);
        })
    }

    //login user
    const loginUser = async (data, redirect) => {
        console.log(API_URL);
        const { email, password, stayLoggedIn } = data;

        // Remove any previous error messages to avoid confusion
        setError(null);

        return axios.post(API_URL + "/user/login", { email, password, stayLoggedIn }).then(async () => {
            await setUserContext(redirect);
        }).catch((error) => {
            if (error.response) {
                setError(error.response.data.message);
            }
            else {
                setError("Problem connecting to server! Please try again later.");
            }
        })
    }

    // Redirect the user to the login page if they are not signed in
    // Logging in / signing up will redirect the user to the page that called this method
    // Returns true if the user is logged in, or false if the user is being redirected
    const requireLogin = () => {
        if (!user) {
            navigate("/login", {state: {from: location}});
            return false;
        }
        return true;
    }

    return {
        registerUser,
        loginUser,
        logoutUser,
        requireLogin,
        error
    }
}
