import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { UserContext } from './../hooks/UserContext';

export default function ProtectedRoute({ children, alternativeElement, element }) {
  const { user, isLoading } = useContext(UserContext);
  const location = useLocation();

  if (isLoading) {
    // Loading
    return <p>Loading...</p>;
  }
  if (user) {
    return (element ? element : children);
  }
  // If one is specified, show a different component when the user isn't logged in
  else if (alternativeElement) {
    return (alternativeElement);
  }

  // Otherwise redirect them to the login page
  return <Navigate to='/login' replace state={{ from: location }} />
}