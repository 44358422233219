import { FileUploadRounded, WarningRounded } from "@mui/icons-material";
import { useState, useContext, useRef } from "react";
import CtaButton from "../components/CtaButton/CtaButton";
import { UserContext } from "../hooks/UserContext";
import { CheckRounded } from "@mui/icons-material";
import axios from "axios";
import RevealIcon from "../components/RevealIcon/RevealIcon";
import Avatar from "../components/Avatar/Avatar";

const API_URL = process.env.REACT_APP_API_URL;

// Copied from Login.js
// Move this to a common file eventually
const programs = [
    {"program": "CompSci", "name": "Computer Science"},
    {"program": "Eng", "name": "Engineering"},
    {"program": "Comm", "name": "Commerce"},
    {"program": "ConEd", "name": "Concurrent Education"},
    {"program": "Arts", "name": "Creative Arts"},
    {"program": "HealthSci", "name": "Health Science"},
    {"program": "Humn", "name": "Humanities"},
    {"program": "La&Cu", "name": "Languages and Cultures"},
    {"program": "L&Ps", "name": "Life and Physical Sciences"},
    {"program": "Nursing", "name": "Nursing"},
];

function getGradYears() {
    var years = [];
    let currentYear = new Date().getFullYear();
    for (var i = 2000; i < currentYear + 20; i++) {
        years.push(i);
    }
    return years;
}

function Error(props) {
    return(
        <div className="Login-error">
            <h5>{props.error}</h5>
        </div>
    )
}

export default function Settings() {

    const { user, refreshUser } = useContext(UserContext);
    const [profileError, setProfileError] = useState(null);
    const [passwordError, setPasswordError] = useState(null);

    const [ visible, setVisible ] = useState(false);
    const [ newVisible, setNewVisible ] = useState(false);
    const [ confirmVisible, setConfirmVisible ] = useState(false);

    const avatarInput = useRef();
    const avatarRef = useRef();
    const bannerInput = useRef();
    const bannerRef = useRef();

    const submitProfileChanges = async (event) => {
        event.preventDefault();
        const submitButton = document.querySelector('#Profile-update-button');
        submitButton.setAttribute('disabled', true);

        const formElement = document.querySelector('#Profile-update-form');
        const formData = new FormData(formElement);
        const formDataJSON = Object.fromEntries(formData);

        // Empty fields are considered unchanged
        // (The placeholder text displays their original value to the user)
        const updateRequest = {};
        for (const field in formDataJSON) {
            updateRequest[field] = formDataJSON[field].trim() || user[field];
        }
        // Bio is allowed to be empty
        updateRequest.bio = formDataJSON.bio;

        await axios.post(API_URL + "/user/updateUser", updateRequest ).then(() => {
            // Temporary solution, to tell the user it worked and they have to use the new one now
            setProfileError("Profile updated successfully!");
            refreshUser();
        }).catch((err) => {
            console.log(err);
            if (err.response) {
                setProfileError(err.response.data.message);
            }
            else {
                setProfileError("Problem connecting to server! Please try again later.");
            }
        });

        submitButton.removeAttribute('disabled');
    }

    const submitPasswordChanges = async (event) => {
        event.preventDefault();

        const submitButton = document.querySelector('#Password-change-button');
        submitButton.setAttribute('disabled', true);

        const formElement = document.querySelector('#Password-change-form');
        const formData = new FormData(formElement);
        const formDataJSON = Object.fromEntries(formData);

        if (formDataJSON.newPassword !== formDataJSON.confirmPassword) {
            setPasswordError("Password and confirmation must match");
            submitButton.removeAttribute('disabled');
            return;
        } else if (formDataJSON.newPassword.length < 8) {
            setPasswordError("Password must be at least 8 characters long");
            submitButton.removeAttribute('disabled');
            return;
        }

        await axios.post(API_URL + "/user/updatePassword", { oldPass: formDataJSON.password, password: formDataJSON.newPassword }).then(() => {
            // Temporary solution, to tell the user it worked and they have to use the new one now
            setPasswordError("Password updated successfully!");
            // Clear password fields to indicate success
            formElement.reset();
        }).catch((err) => {
            console.log(err);
            if (err.response) {
                setPasswordError(err.response.data.message);
            }
            else {
                setPasswordError("Problem connecting to server! Please try again later.");
            }
        });

        submitButton.removeAttribute('disabled');
    }

    const revealPassword = (e, inputFieldId, setFunction) => {
        e.preventDefault();
        const input = document.getElementById(inputFieldId);

        if (input.type === "password") {
            input.type = "text";
            setFunction(true);
            //input.setAttribute("title", "Hide password");
        }
        else {
            input.type = "password";
            setFunction(false);
            //input.setAttribute("title", "Show password");
        }
    }

    // Preview updating

    const updateUserName = () => {
        // Update the preview to show the username the user entered in the settings
        // Empty fields show the currently saved value
        const newUsername = document.getElementById("Username-input").value || user.userName;

        document.getElementById("Username-preview").innerText = "@" + newUsername;

        // Hide the previous error once the user starts changing things
        setProfileError(null);
    }

    const updateName = () => {
        // Update the preview to show the name the user entered in the settings
        // Empty fields show the currently saved value
        const newFirstName = document.getElementById("First-name-input").value || user.firstName;
        const newLastName = document.getElementById("Last-name-input").value || user.lastName;

        document.getElementById("Name-preview").innerText = newFirstName + " " + newLastName;

        // Hide the previous error once the user starts changing things
        setProfileError(null);
    }

    const updateProgramAndGradDate = () => {
        // Update the preview to show the program and grad date the user entered in the settings
        const newProgram = document.getElementById("Program-input").value;
        const newGradYear = document.getElementById("Grad-year-input").value;

        document.getElementById("Program-grad-year-preview").innerText = newProgram + " " + newGradYear;

        // Hide the previous error once the user starts changing things
        setProfileError(null);
    }

    const updateBioInput = () => {
        // Update the preview to show the bio the user entered in the settings
        // If the bio is left empty, a message is shown in its place
        const counter = document.getElementById("Bio-character-counter");
        const input = document.getElementById("Bio-input");
        const preview = document.getElementById("Bio-preview");

        counter.innerText = input.value.trim().length + "/300";
        preview.innerText = input.value.trim() || "This user hasn't written anything about themselves";

        // Hide the previous error once the user starts changing things
        setProfileError(null);
    }

    const uploadAvatar = async () => {
        // Display image on preview
        const reader = new FileReader();
        reader.onload = (event) => {
            avatarRef.current.src = event.target.result;
        }
        reader.readAsDataURL(avatarInput.current.files[0]);
        // Upload to server
        const formData = new FormData();
        formData.append("avatar", avatarInput.current.files[0]);
        await axios.post(API_URL + '/user/avatar', formData, { headers: {'Content-Type': 'multipart/form-data'}});
    }

    const uploadBanner = async () => {
        // Display image on preview
        const reader = new FileReader();
        reader.onload = (event) => {
            bannerRef.current.src = event.target.result;
        }
        reader.readAsDataURL(bannerInput.current.files[0]);
        // Upload to server
        const formData = new FormData();
        formData.append("banner", bannerInput.current.files[0]);
        await axios.post(API_URL + '/user/banner', formData, { headers: {'Content-Type': 'multipart/form-data'}});
    }

    // Settings page

    return (
        <main>
            <div className='Feed Pad-top'>
                <h2>Profile Settings</h2>
                <p>Modify your public profile here. To customize your profile picture or banner, click on the respective image in the preview to upload a new file.</p>

                <form id="Profile-update-form" onSubmit={submitProfileChanges}>

                    <div className="Label-and-input">
                        <p>Username</p>
                        <div className="Input-row">
                            <input className="Input-box" id="Username-input" name="userName" maxLength={40} defaultValue={user.userName} placeholder={user.userName} onChange={updateUserName}/>
                        </div>
                    </div>
                    <div className="Label-and-input">
                        <p>Name</p>
                        <div className="Input-row">
                            <input className="Input-box" id="First-name-input" name="firstName" maxLength={40} defaultValue={user.firstName} placeholder={user.firstName} onChange={updateName}/>
                            <input className="Input-box" id="Last-name-input" name="lastName" maxLength={40} defaultValue={user.lastName} placeholder={user.lastName} onChange={updateName}/>
                        </div>
                    </div>

                    <div className="Input-row">
                        <div className="Label-and-input">
                            <p>Program</p>
                            <select className="Input-box" id="Program-input" name="program" defaultValue={user.program} onChange={updateProgramAndGradDate}>
                                {programs.map((item, index) => { return <option value={item.program} key={index}>{item.name}</option>; })}
                            </select>
                        </div>
                        <div className="Label-and-input">
                            <p>Graduation Year</p>
                            <select className="Input-box" id="Grad-year-input" name="gradDate" defaultValue={user.gradDate} onChange={updateProgramAndGradDate}>
                                {getGradYears().map((year, index) => { return <option defaultValue={year} key={index}>{year}</option>; })}
                            </select>
                        </div>
                    </div>

                    <div className="Label-and-input">
                        <p>Bio</p>
                        <textarea className="Input-box" id="Bio-input" name="bio" maxLength={300} defaultValue={user.bio} onChange={updateBioInput}/>
                        <p className="Character-counter" id="Bio-character-counter">{user?.bio?.length || 0}/300</p>
                    </div>

                    <CtaButton type="submit" id="Profile-update-button" text="Save Changes" icon={<CheckRounded/>}/>
                    {profileError && <Error error={profileError}/>}
                </form>
                <br/>

                <h2>Account Settings</h2>
                <p>Private account settings.</p>
                <p>Your email is <b>{user.email}</b> (Can't be changed).</p>

                <form id="Password-change-form" onSubmit={submitPasswordChanges}>

                    <input type="hidden" id="email" name="email" autoComplete="email" readOnly value={user.email /* Enhances autocomplete when multiple accounts are saved */}/>
                    <div className="Input-row">
                        <div className="Label-and-input">
                            <p>Current Password</p>
                            <div className="Input-row Password-container">
                                <input className="Input-box" id="Password" type="password" name="password" placeholder="Password" autoComplete="current-password" required onChange={() => {setPasswordError(null)}}/>
                                <button className="Password-reveal" type="button" onClick={(e) => {revealPassword(e, "Password", setVisible)}} title="Show password"><RevealIcon active={visible}/></button>
                            </div>
                        </div>
                        <div className="Spacer"/>
                    </div>
                    <div className="Input-row">
                        <div className="Label-and-input">
                            <p>New Password</p>
                            <div className="Input-row Password-container">
                                <input className="Input-box" id="New-password" type="password" name="newPassword" placeholder="New Password" autoComplete="new-password" required onChange={() => {setPasswordError(null)}}/>
                                <button className="Password-reveal" type="button" onClick={(e) => {revealPassword(e, "New-password", setNewVisible)}} title="Show password"><RevealIcon active={newVisible}/></button>
                            </div>
                        </div>
                        <div className="Label-and-input">
                            <p>Confirm Password</p>
                            <div className="Input-row Password-container">
                                <input className="Input-box" id="Confirm-password" type="password" name="confirmPassword" placeholder="Confirm password" autoComplete="new-password" required onChange={() => {setPasswordError(null)}}/>
                                <button className="Password-reveal" type="button" onClick={(e) => {revealPassword(e, "Confirm-password", setConfirmVisible)}} title="Show password"><RevealIcon active={confirmVisible}/></button>
                            </div>
                        </div>
                    </div>
                    <CtaButton type="submit" id="Password-change-button" text="Change Password" icon={<WarningRounded/>}/>
                    {passwordError && <Error error={passwordError}/>}
                </form>
                <br/>
            </div>

            <div className='Sidebar'>
                <div className="Sidebar-banner-container">
                    <input ref={bannerInput} style={{display: "none"}} type="file" accept="image/png, image/gif, image/jpeg, image/webp" onChange={uploadBanner}/>
                    <img className="Sidebar-banner" src={API_URL + "/images/userbanner/" + user._id + ".png"} alt="" ref={bannerRef}/>
                    <div className="Sidebar-banner-upload" onClick={() => bannerInput.current.click()}>
                        <FileUploadRounded/>
                    </div>
                    <div style={{position: "relative"}}>
                        <div className="Sidebar-banner-overlay">
                            <input ref={avatarInput} style={{display: "none"}} type="file" accept="image/png, image/gif, image/jpeg, image/webp" onChange={uploadAvatar}/>
                            <Avatar userId={user._id} imageRef={avatarRef} className="Sidebar-avatar Upload-button"/>
                            <div className="Avatar-upload" onClick={() => avatarInput.current.click()}>
                                <FileUploadRounded/>
                            </div>
                        </div>
                    </div>
                </div>
                <h2 id="Name-preview">{user.firstName + " " + user.lastName}</h2>
                <p className="Sidebar-username" id="Username-preview">{"@" + user.userName}</p>
                <p className="Sidebar-username" id="Program-grad-year-preview">{user.program + " " + user.gradDate}</p>
                <div className="Separator"></div>
                <p id="Bio-preview">{user.bio}</p>
            </div>
        </main>
    );

}