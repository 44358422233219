import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../hooks/UserContext';

export default function SigninRoute({ children, element }) {

  const { user, isLoading } = useContext(UserContext);

  if (isLoading) {
    // Loading
    return <p>Loading...</p>;
  }
  // Only allow signed-out users to see the signin pages
  if (!user) {
    return (element ? element : children);
  }

  // Redirect logged in users to the home page
  return <Navigate to='/'/>
}