import ReactTimeAgo from "react-time-ago";
import "./Comment.css"
import { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../hooks/UserContext";
import Avatar from "../Avatar/Avatar";
import { ArrowDownwardRounded, ArrowDropDownRounded, ArrowRightRounded, ArrowUpwardRounded, CloseRounded, ReplyOutlined } from "@mui/icons-material";
import axios from "axios";
import CtaButton from "../CtaButton/CtaButton";
import useAuth from "../../hooks/useAuth";
import classNames from "classnames";

const API_URL = process.env.REACT_APP_API_URL;

// Forum and post IDs are temporary solutions for allowing comments to send complete data to the backend
// Nesting depth helps determine whether to hide comments by default
export default function Comment({commentId, forumId, postId, nestingDepth}) {

    const loggedInUser = useContext(UserContext).user;
    const [ userInfo, setUserInfo ] = useState({});
    const [ commentInfo, setCommentInfo ] = useState({});

    const [ vote, setVote ] = useState(null);
    const [ showingReplies, setShowingReplies ] = useState(true);
    const [ showingReplyInput, setShowingReplyInput ] = useState(false);
    const replyInputRef = useRef();
    const replyButtonRef = useRef();
    const { requireLogin } = useAuth();

    let repliesRef = useRef(null); // Populated if/when the comment is visible

    nestingDepth ??= 0;

    // If the user clicks the link before user info is loaded then stay on this page
    const userProfileLink = userInfo._id ? ("/user/" + userInfo._id) : "#";

    useEffect(() => {
        axios.get(API_URL + "/comment/" + commentId).then((result) => {
            const comment = result.data;
            setCommentInfo(result.data);

            // Hide comments more than 3 levels deep by default. When the user clicks to reveal
            // them further levels are not hidden.
            // Extra cases can be added in here in the future that take into account the number of replies
            if (nestingDepth === 2) {
                setShowingReplies(false);
            }

            axios.get(API_URL + "/user/" + comment.userID).then((result) => {
                setUserInfo(result.data.data);
            });
        });
    }, [commentId, nestingDepth])

    // Vote check
    useEffect(() => {
        axios.get(API_URL + "/vote/hasVoted/" + commentId)
            .then((result) => {
                if (result.data.hasVoted) {
                    setVote(result.data.isLike ? "Liked" : "Disliked");
                }
            }).catch((err) => {
                console.log(err);
            });
    }, [commentId]);

    const openReply = (e) => {
        e.preventDefault();
        if (requireLogin()) {
            setShowingReplyInput(true);
        }
    }

    const cancelReply = (e) => {
        e.preventDefault();

        setShowingReplyInput(false);
        replyInputRef.current.value = "";
    }

    const sendReply = async (e) => {
        e.preventDefault();
        const button = replyButtonRef.current;
        const input = replyInputRef.current;

        if (button.disabled) {
            // On certain browsers disabled buttons still fired the event
            return;
        }
        button.setAttribute("disabled", true);

        const info = {
            userID: loggedInUser._id,
            parentID: commentId,
            forumID: forumId,
            masterPostID: postId,
            content: input.value
        }

        await axios.post(API_URL + "/comment", info).then((res) => {
            setShowingReplyInput(false);
            replyInputRef.current.value = "";

            // Add the newly created comment to the list of child comments so the user can see it
            const info = commentInfo;
            info.childCommentIDs.push(res.data.data._id);
            setCommentInfo(info);
            setShowingReplies(true);
        }).catch((err) => { console.log(err)});

        button.removeAttribute("disabled");
    }

    const like = async () => {
        if (vote === "Liked") {
            removeLike();
            return;
        }
        
        await axios.get(API_URL + "/vote/like/" + (commentInfo._id || postId)).then(res => {
            const info = commentInfo;
            info.votes += res.data.voteChange;
            setCommentInfo(info);
            setVote("Liked");
        })
    }

    const dislike = async () => {
        if (vote === "Disliked") {
            removeLike();
            return;
        }

        await axios.get(API_URL + "/vote/dislike/" + (commentInfo._id || postId)).then(res => {
            const info = commentInfo;
            info.votes += res.data.voteChange;
            setCommentInfo(info);
            setVote("Disliked");
        })
    }

    const removeLike = async () => {
        await axios.get(API_URL + "/vote/removeLike/" + (commentInfo._id || postId)).then(res => {
            const info = commentInfo;
            info.votes += res.data.voteChange;
            setCommentInfo(info);
            setVote(null);
        })
    }

    let hiddenRepliesText = "replies hidden";
    if (commentInfo.childCommentIDs?.length === 1) {
        hiddenRepliesText = "reply hidden";
    }

    if (showingReplies) {
        repliesRef.current = commentInfo?.childCommentIDs?.map((id) => {
            return <Comment commentId={id} postId={postId} forumId={forumId} key={id} nestingDepth={nestingDepth + 1}/>;
        });
    }

    return (
        <>
        <div className="Comment">
            <div className="Comment-left">
                <Link to={userProfileLink}>
                    <Avatar user={userInfo} className="Comment-avatar"/>
                </Link>
                <div className="Vote-container">
                    <div className="Vote-arrows">
                    <div className={classNames("Like-button", { Liked: vote === "Liked"})} onClick={like}><ArrowUpwardRounded/></div>
                    <div className={classNames("Like-button", { Disliked: vote === "Disliked"})}  onClick={dislike}><ArrowDownwardRounded/></div>
                    </div>
                    <p className={classNames("Vote-counter", { Liked: vote === "Liked"}, { Disliked: vote === "Disliked"})} >{commentInfo.votes}</p>
                </div>
            </div>
            <div className="Comment-center">
                <div className="Comment-flex-row">
                    <Link to={userProfileLink} className="Comment-author">{"By " + (userInfo.userName || "")}</Link>
                    { commentInfo.createdAt ?
                    <>
                        <ReactTimeAgo date={Date.parse(commentInfo.createdAt)} locale="en-US" timeStyle="short-minute-now" className="Time-ago"/>
                    </>
                        : null
                    }
                </div>
                <div className="Comment-flex-row">
                    <p className="Comment-content">{commentInfo.content}</p>
                    <button className="Comment-reply-button" onClick={openReply}>
                        <ReplyOutlined className="Comment-reply-icon"/>
                    </button>
                </div>
            </div>
        </div>

        <div className={"Reply-container" + ( showingReplyInput ? "" : " hide")}>
            <textarea className="Reply-input" name="Comment-reply-box" ref={replyInputRef} placeholder={"Replying to " + userInfo.userName}/>
            <div className="Reply-buttons">
                <CtaButton outlined small text="Cancel" icon={<CloseRounded/>} onClick={cancelReply}/>
                <CtaButton small text="Reply"icon={<ReplyOutlined/>} onClick={sendReply} buttonRef={replyButtonRef}/>
            </div>
        </div>

        { commentInfo.childCommentIDs?.length > 0 && // Only create all this html if there are children
            <>
            {/* Displays replies, with a button to hide them along the side
                Always included in the html, to preserve the state of replies.
                Maintaining the state reduces the number of network requests and
                allows remembering which replies have been hidden underneath this one */}
            <div className={"Comment-nesting-container " + (showingReplies ? "" : "hide")}>
                <button className="Comment-visibility-button Vertical" onClick={() => {setShowingReplies(false)}}>
                    <ArrowDropDownRounded className="Comment-visibility-icon"/>
                    <div className="Comment-nesting-bar"/>
                </button>
                <div className="Comment-replies">
                    {
                    // Empty until first shown, afterwards contains all replies
                    // even when hidden to maintain state
                    repliesRef.current
                    }
                </div>
            </div>

            { !showingReplies && // Shown when replies are hidden - click to reveal them
            <button className="Comment-visibility-button" onClick={() => {setShowingReplies(true)}}>
                <ArrowRightRounded className="Comment-visibility-icon"/>
                <p>{commentInfo.childCommentIDs.length} {hiddenRepliesText}</p>
            </button>
            }
            </>
        }
        </>
    );
}