import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import FeedPost from "../components/FeedPost/FeedPost";
import NotFound from "./NotFound";
import Avatar from "../components/Avatar/Avatar";

const API_URL = process.env.REACT_APP_API_URL;

export default function User() {

    // Get user ID from the url
    const { id } = useParams();
    const [ userInfo, setUserInfo ] = useState(null);
    const [ statusIs404, setStatusIs404 ] = useState(false);

    // Find a way to do this without rerendering the whole page
    const [ postArray, setPostArray ] = useState(null);

    const lastTimestamp = useRef(undefined);
    const loadingNextPage = useRef(true); // Initially True while loading first page
    const [ reachedEnd, setReachedEnd ] = useState(false);

    useEffect(() => {
        const getNextPosts = async () => {
            loadingNextPage.current = true;

            try {
                const response = await axios.get(API_URL + "/post/fromUser/" + id, { params: {start: lastTimestamp.current} });
                console.log(response.data);
                if (response.data.length === 0) {
                    setReachedEnd(true);
                }
                else {
                    lastTimestamp.current = response.data[response.data.length-1].createdAt;
                    setPostArray(posts => posts.concat(response.data));
                }
            }
            catch (error) {
                console.log(error);
            }
            loadingNextPage.current = false;
        }

        const handleScroll = (event) => {
            const offsetHeight = document.documentElement.offsetHeight;
            const innerHeight = window.innerHeight;
            const scrollTop = document.documentElement.scrollTop;

            const hasReachedBottom = offsetHeight - (innerHeight + scrollTop) <= 300;

            if (hasReachedBottom && !loadingNextPage.current && !reachedEnd) {
                console.log("Fetching next posts");
                getNextPosts();
            }
        }

        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll)
    }, [id, reachedEnd]);

    useEffect(() => {
        setStatusIs404(false);
        // User info to populate sidebar
        axios.get(API_URL + "/user/" + id).then((res) => {
            setUserInfo(res.data.data);
        }).catch((err) => {
            console.log(err);
            // Display a 404 message if the user doesn't exist
            if (err.response.status === 404 || err.response.status === 400) {
                setStatusIs404(true);
            }
        });

        // User's posts
        axios.get(API_URL + "/post/fromUser/" + id).then((response) => {
            lastTimestamp.current = response.data[response.data.length-1].createdAt;
            setPostArray(response.data);
            loadingNextPage.current = false;
        }).catch((err) => {
            console.log(err);
            setPostArray([]);
        });
    }, [id]);

    var posts = postArray?.map(function(post){
        return <FeedPost user={userInfo} post={post} forumId={post.forumID} key={post._id} />;
    });

    // 404 Error page if there is no user with the given ID
    if (statusIs404) {
        return <NotFound subject="user"/>;
    }

    return (
        <main>
            <div className='Feed'>
                <div className='Feed-header'>
                    <button className='Feed-header-button Selected' onClick={() => {/* Set sorting method, refreshes feed */}}>All</button>
                    <button className='Feed-header-button' disabled={true} onClick={() => {/* Set sorting method, refreshes feed */}}>Posts</button>
                    <button className='Feed-header-button' disabled={true} onClick={() => {/* Set sorting method, refreshes feed */}}>Comments</button>
                </div>
                <div id="Posts-container">
                    { postArray ?
                        posts
                        : null
                    }
                    { (postArray && postArray.length === 0) ? <><br/><h3 className="Center-text">{userInfo.firstName + " hasn't posted anything yet"}</h3></> : null }
                    { reachedEnd && <h3 className="End-notice">You've reached the end!</h3>}
                </div>
            </div>
            <div className='Sidebar'>
                { userInfo ? // Don't show the sidebar contents until the user data is fetched
                <>
                    <div className="Sidebar-banner-container">
                        <img className="Sidebar-banner" src={API_URL + "/images/userbanner/" + userInfo._id + ".png"} alt="" onError={(error) => {error.target.src = "/placeholder-large.svg"}}/>
                        <div style={{position: "relative"}}>
                            <div className="Sidebar-banner-overlay">
                                <Avatar user={userInfo} userId={id} className="Sidebar-avatar"/>
                            </div>
                        </div>
                    </div>
                    <h2>{userInfo.firstName + " " + userInfo.lastName}</h2>
                    <p className="Sidebar-username" >{"@" + userInfo.userName}</p>
                    <p className="Sidebar-username" >{userInfo.program + " " + userInfo.gradDate}</p>
                    <div className="Separator"></div>
                    <p>{userInfo.bio}</p>
                </>
                :
                <>
                    <div className="Sidebar-banner-container">
                        <div className="Sidebar-banner Loading"/>
                        <div style={{position: "relative"}}>
                            <div className="Sidebar-banner-overlay">
                                <Avatar isLoading={true} className="Sidebar-avatar"/>
                            </div>
                        </div>
                    </div>
                    <div className="Loading-header"/>
                    {/* The rest of the sidebar elements lack placeholders */}
                </>
                }
            </div>
        </main>
    );
}