import { useNavigate } from "react-router-dom";
import CtaButton from "../components/CtaButton/CtaButton";
import { AddRounded } from "@mui/icons-material";
import { useEffect, useState } from "react";
import axios from "axios";
import FeedForum from "../components/FeedPost/FeedForum";
import classNames from "classnames";

const API_URL = process.env.REACT_APP_API_URL;

export default function ForumList() {

    const [forumArray, setForumArray] = useState(null);
    const [forumSort, setForumSort] = useState("date");

    const navigate = useNavigate();

    useEffect(() => {
        // Get a list of every forum, to make a list of links
        axios.get(API_URL + "/forum/").then((res) => {
            setForumArray(res.data);
        }).catch((err) => {
            console.log(err);
            setForumArray([]);
        })
    }, []);

    if (forumSort === "date") {
        forumArray?.sort((a, b) => { return a.createdAt > b.createdAt ? 1 : -1 });
    } else if (forumSort === "alphabetical") {
        forumArray?.sort((a, b) => { return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1 });
    }

    const forums = forumArray?.map((forum) => {
        return <FeedForum forumId={forum._id} key={forum._id} />;
    });

    return (
        <main>
            <div className='Feed'>
                <div className='Feed-header'>
                    <button className={classNames("Feed-header-button", { Selected: forumSort === "date" })}
                        onClick={() => { setForumSort("date") }}>
                            Date
                    </button>
                    <button className={classNames("Feed-header-button", { Selected: forumSort === "alphabetical" })}
                        onClick={() => { setForumSort("alphabetical") }}>
                            A-Z
                    </button>
                    <div className='Spacer' />
                    <CtaButton onClick={() => { navigate('/createForum') }} text="Create Forum" icon={<AddRounded />} />
                </div>
                <div id="Posts-container">
                    {forumArray ?
                        forums
                        : null
                    }
                    {(forumArray && forumArray.length === 0) ?
                        <>
                            <br /><h3 className="Center-text">"We ran into a problem finding the forums"</h3>
                            <p className="Center-text">Try checking your internet connection and reloading the page</p>
                        </>
                        : null
                    }
                </div>

            </div>
            <div className='Sidebar'>
                <div className="Sidebar-banner-container">
                    <img className="Sidebar-banner" src="/VirtualExpHomepage.png" alt="" />
                </div>
                <h2>All Forums</h2>
                <div className="Separator"></div>
                <p>Discover new communities here!</p>
                <p>This is a listing of every forum. If you can't find what you're looking for, feel free to create it yourself!</p>
            </div>
        </main>
    );
}