import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./FeedForum.css"

const API_URL = process.env.REACT_APP_API_URL;

export default function FeedForum({forumId}) {

    const [forumInfo, setForumInfo] = useState({});

    const navigate = useNavigate();
    const forumLink = "/forum/" + forumInfo._id;

    useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        axios.get(API_URL + "/forum/" + forumId, {cancelToken: cancelToken.token}).then((result) => {
            setForumInfo(result.data);
        });

        // Cleanup unfinished network requests when navigating away
        return () => {
            cancelToken.cancel("User left page");
        };
    }, [forumId]);

    const clicked = async () => {
        navigate(forumLink);
    }

    const stopPropagation = (e) => {
        e.stopPropagation();
    }

    return (
        <div className="Feed-forum" onClick={clicked}>
            <div className="Feed-forum-left" onClick={stopPropagation}>
                <Link to={forumLink}>
                    <div className="Z-index-buffer">
                        <div className="Avatar-outer">
                            <img className="Feed-forum-image" src={forumInfo.forumImage} alt="" onError={(error) => {error.target.src = "/placeholder-small.svg"}}/>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="Feed-forum-main" onClick={stopPropagation}>
                <Link to={forumLink} className="No-underline">
                    <h3 className="Feed-forum-title">{forumInfo.name}</h3>
                </Link>
                <p className="Feed-forum-description">{forumInfo.bio}</p>
            </div>
        </div>
    );
}