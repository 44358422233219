import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import useAuth from "../hooks/useAuth";
import CtaButton from "../components/CtaButton/CtaButton";
import RevealIcon from "../components/RevealIcon/RevealIcon";
import { ArrowForwardRounded } from "@mui/icons-material";

function Error(props) {
    return(
        <div className="Login-error">
            <h5>{props.error}</h5>
        </div>
    )
}

export default function Login() {
    const { loginUser, error } = useAuth();
    const [ visible, setVisible ] = useState(false);
    const { state: locationState } = useLocation();

    const revealPassword = (e, inputFieldId) => {
        e.preventDefault(); // Prevent form submission when the button is pressed
        const input = document.getElementById(inputFieldId);

        if (input.type === "password") {
            input.type = "text";
            setVisible(true);
            //input.setAttribute("title", "Hide password");
        }
        else {
            input.type = "password";
            setVisible(false);
            //input.setAttribute("title", "Show password");
        }
    }

    const submitLoginForm = async (event) => {
        event.preventDefault();

        const submitButton = document.querySelector('#Login-button');
        submitButton.setAttribute('disabled', true);

        const formElement = document.querySelector('#Login-form');
        const formData = new FormData(formElement);
        const formDataJSON = Object.fromEntries(formData);

        await loginUser(formDataJSON, locationState?.from);

        submitButton.removeAttribute('disabled');
    }

    return (
        <main>
            <div className='Signin'>
                <h2 className="Center-text">Login</h2>
                <div className="Separator"></div>
                <p className="Center-text">Please enter your login information to proceed</p>
                <br/>
                <form id="Login-form" onSubmit={submitLoginForm}>
                    <input className="Input-box" type="email"  name="email" placeholder="Email @queensu.ca" autoComplete="email" required/>
                    <div className="Input-row Password-container">
                        <input className="Input-box" id="Password" type="password" name="password" placeholder="Password" autoComplete="current-password" required/>
                        <button className="Password-reveal" type="button" onClick={(e) => {revealPassword(e, "Password")}} title="Show password"><RevealIcon active={visible}/></button>
                    </div>
                    <label className="Checkbox-label">
                        <input className="Stay-signed-in" type="checkbox" name="stayLoggedIn"/>
                        Stay signed in
                    </label>
                    <Link to="/signup" className="Link" state={locationState}>Don't have an account? Sign up!</Link>
                    <CtaButton type="submit" id="Login-button" text="Login" icon={<ArrowForwardRounded/>} />
                    <div className="Signin-error">
                        {error && <Error error={error}/>}
                    </div>
                </form>
            </div>
        </main>
    );
}