import { useContext, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import NotFound from "./NotFound";
import axios from "axios";
import Comment from "../components/Comment/Comment";
import CtaButton from "../components/CtaButton/CtaButton";
import { ArrowDownwardRounded, ArrowUpwardRounded, ChatBubbleOutlineRounded, CloseRounded, ReplyOutlined, VisibilityOutlined } from "@mui/icons-material";
import { UserContext } from "../hooks/UserContext";
import useAuth from "../hooks/useAuth";
import Avatar from "../components/Avatar/Avatar";
import classNames from "classnames";
import ReactTimeAgo from "react-time-ago";
import "./Post.css"

const API_URL = process.env.REACT_APP_API_URL;

export default function Post() {

    // Get post ID from the url
    const { id } = useParams();
    const { user } = useContext(UserContext);
    const [ postInfo, setPostInfo ] = useState(null);
    const [ forumInfo, setForumInfo ] = useState(null);
    const [ userInfo, setUserInfo ] = useState(null);
    const [ vote, setVote ] = useState(null);
    const [ showingCommentInput, setShowingCommentInput ] = useState(false);
    const [ statusIs404, setStatusIs404 ] = useState(false);
    const { requireLogin } = useAuth();

    const commentInputRef = useRef();
    const commentButtonRef = useRef();

    useEffect(() => {
        const cancelToken = axios.CancelToken.source();

        axios.get(API_URL + "/post/" + id, {cancelToken: cancelToken.token}).then((res) => {
            const post = res.data; // postInfo wont reflect the changes in setPostInfo right away
            setPostInfo(post);

            axios.get(API_URL + "/forum/" + post.forumID, {cancelToken: cancelToken.token}).then((res) => {
                setForumInfo(res.data);
            }).catch((err) => {
                if (!axios.isCancel(err)) {
                    console.log(err);
                    // Make the sidebar show an error message
                    setForumInfo({name: "Error", bio: "We ran into a problem fetching data about the forum this was posted in :("});
                }
            });

            // Get informatino about the post author
            axios.get(API_URL + "/user/" + post.userID).then((response) => {
                setUserInfo(response.data.data);
            }).catch((err) => {
                console.log(err)
                // Might make it show undefined instead of erroring if the request fails
                setUserInfo({});
            });

        }).catch((err) => {
            if (!axios.isCancel(err)) {
                // Redirect the user to page saying the post couldn't be found
                console.log(err);
                setStatusIs404(true);
            }
        })

        // Cleanup unfinished network requests when navigating away
        return () => {
            cancelToken.cancel("User left page");
        };
    }, [id]);

    // Vote check
    useEffect(() => {
        axios.get(API_URL + "/vote/hasVoted/" + id)
            .then((result) => {
                if (result.data.hasVoted) {
                    setVote(result.data.isLike ? "Liked" : "Disliked");
                }
            }).catch((err) => {
                console.log(err);
            });
    }, [id]);

    const cancelComment = (e) => {
        e.preventDefault();

        setShowingCommentInput(false);
        commentInputRef.current.value = "";
    }

    const sendComment = async (e) => {
        e.preventDefault();
        const button = commentButtonRef.current;
        const input = commentInputRef.current;

        if (button.disabled) {
            return;
        }
        button.setAttribute("disabled", true);

        const info = {
            userID: user._id,
            parentID: id,
            forumID: postInfo.forumID,
            masterPostID: id,
            content: input.value
        }

        await axios.post(API_URL + "/comment", info).then((res) => {
            setShowingCommentInput(false);
            commentInputRef.current.value = "";

            // Add the newly created comment to the top of the list so the user can see it
            const info = postInfo;
            info.childCommentIDs.unshift(res.data.data._id);
            setPostInfo(info);
        }).catch((err) => { console.log(err)});

        button.removeAttribute("disabled");
    }

    const like = async () => {
        if (vote === "Liked") {
            removeLike();
            return;
        }
        
        await axios.get(API_URL + "/vote/like/" + id).then(res => {
            const info = postInfo;
            info.votes += res.data.voteChange;
            setPostInfo(info);
            setVote("Liked");
        })
    }

    const dislike = async () => {
        if (vote === "Disliked") {
            removeLike();
            return;
        }

        await axios.get(API_URL + "/vote/dislike/" + id).then(res => {
            const info = postInfo;
            info.votes += res.data.voteChange;
            setPostInfo(info);
            setVote("Disliked");
        })
    }

    const removeLike = async () => {
        await axios.get(API_URL + "/vote/removeLike/" + id).then(res => {
            const info = postInfo;
            info.votes += res.data.voteChange;
            setPostInfo(info);
            setVote(null);
        })
    }


    // 404 Error page if there is no post with the given ID
    if (statusIs404) {
        return <NotFound subject="post"/>;
    }

    // Translate the post's comment id list into a bunch of comment components
    let comments = null;
    if (postInfo) {
        comments = postInfo.childCommentIDs.map(
            commentId => <Comment commentId={commentId} postId={id} forumId={postInfo.forumID} key={commentId}/>
        );

        // If there arent any comments on the post show an empty state instead
        if (comments.length === 0) {
            comments =
                <>
                    <p className="Center-text">It's pretty quiet in here...</p>
                    <br/>
                </>
        }
    }

    return (
        <main>
            <div className='Feed'>
                <div className="Post">
                    <div className="Post-wrap-container">
                        <div className="Post-left">
                            <Link to={"/user/" + postInfo?.userID}>
                                <Avatar userId={userInfo?._id} className="Post-avatar"/>
                            </Link>
                            <div className="Vote-container">
                                <div className="Vote-arrows">
                                <div className={classNames("Like-button", { Liked: vote === "Liked"})} onClick={like}><ArrowUpwardRounded/></div>
                                <div className={classNames("Like-button", { Disliked: vote === "Disliked"})}  onClick={dislike}><ArrowDownwardRounded/></div>
                                </div>
                                <p className={classNames("Vote-counter", { Liked: vote === "Liked"}, { Disliked: vote === "Disliked"})} >{postInfo?.votes}</p>
                            </div>
                        </div>
                        <div className="Post-center">
                            <h2 className="Post-title">{postInfo?.title || ""}</h2>
                            <Link className="Post-author" to={"/user/" + postInfo?.userID}>{"By " + userInfo?.userName}</Link>
                            <p className="Post-content">{postInfo?.content}</p>
                        </div>
                    </div>
                    { postInfo &&
                    <div className="Post-right">
                        <p className="Post-stat">
                        <VisibilityOutlined className="Stat-icon"/>{postInfo.views}</p>
                        <p className="Post-stat"><ChatBubbleOutlineRounded className="Stat-icon"/>{postInfo.childCommentIDs.length}</p>
                        <ReactTimeAgo date={Date.parse(postInfo.createdAt)} locale="en-US" timeStyle="short-minute-now" className="Time-ago"/>
                    </div>
                    }
                </div>

                <div className="Comment-bar">
                    <h3 className="Comments-label">Comments</h3>
                    <CtaButton small text="Comment" icon={<ReplyOutlined/>}
                        outlined={showingCommentInput} disabled={showingCommentInput}
                        onClick={() => {
                                if (requireLogin()) {
                                    setShowingCommentInput(true);
                                }
                            }}
                        />
                </div>
                <div className={"Reply-container" + ( showingCommentInput ? "" : " hide")}>
                    <textarea className="Reply-input" name="Comment-box" ref={commentInputRef} placeholder={"Commenting on \"" + postInfo?.title + "\""}/>
                    <div className="Reply-buttons">
                        <CtaButton outlined small text="Cancel" icon={<CloseRounded/>} onClick={cancelComment}/>
                        <CtaButton small text="Comment"icon={<ReplyOutlined/>} onClick={sendComment} buttonRef={commentButtonRef}/>
                    </div>
                </div>


                {comments}
            </div>
            <div className='Sidebar'>
            { forumInfo ? // Don't show the sidebar contents until the forum data is fetched
                <>
                    <div className="Sidebar-banner-container">
                        <Link to={"/forum/" + forumInfo._id}>
                            <img className="Sidebar-banner" src={forumInfo.forumImage} alt="" onError={(error) => {error.target.src = "/placeholder-large.svg"}}/>
                        </Link>
                    </div>
                    <Link className="No-underline" to={"/forum/" + forumInfo._id}><h2>{forumInfo.name}</h2></Link>
                    <div className="Separator"></div>
                    <p>{forumInfo.bio}</p>
                </>
                :
                <>
                    <div className="Sidebar-banner-container">
                        <div className="Sidebar-banner Loading"/>
                    </div>
                    <div className="Loading-header"/>
                </>
            }
            </div>
        </main>
    );
}