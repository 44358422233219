import { Link, useLocation } from "react-router-dom"
import useAuth from "../hooks/useAuth";
import { useState } from "react";
import CtaButton from "../components/CtaButton/CtaButton";
import RevealIcon from "../components/RevealIcon/RevealIcon";
import { ArrowForwardRounded } from "@mui/icons-material";

const programs = [
    {"program": "CompSci", "name": "Computer Science"},
    {"program": "Eng", "name": "Engineering"},
    {"program": "Comm", "name": "Commerce"},
    {"program": "ConEd", "name": "Concurrent Education"},
    {"program": "Arts", "name": "Creative Arts"},
    {"program": "HealthSci", "name": "Health Science"},
    {"program": "Humn", "name": "Humanities"},
    {"program": "La&Cu", "name": "Languages and Cultures"},
    {"program": "L&Ps", "name": "Life and Physical Sciences"},
    {"program": "Nursing", "name": "Nursing"},
];

function getGradYears() {
    var years = [];
    let currentYear = new Date().getFullYear();
    for (var i = 2000; i < currentYear + 20; i++) {
        years.push(i);
    }
    return years;
}

function Error(props) {
    return(
        <div className="Login-error">
            <h5>{props.error}</h5>
        </div>
    )
}

export default function Signup() {
    const { registerUser, error } = useAuth();

    const [ visible, setVisible ] = useState(false);
    const [ confirmVisible, setConfirmVisible ] = useState(false);
    const { state: locationState } = useLocation();

    const revealPassword = (e, inputFieldId, setFunction) => {
        e.preventDefault(); // Prevent form submission when the button is pressed
        const input = document.getElementById(inputFieldId);

        if (input.type === "password") {
            input.type = "text";
            setFunction(true);
            //input.setAttribute("title", "Hide password");
        }
        else {
            input.type = "password";
            setFunction(false);
            //input.setAttribute("title", "Show password");
        }
    }

    const submitSignupForm = async (event) => {
        event.preventDefault();

        const submitButton = document.querySelector('#Signup-button');
        submitButton.setAttribute('disabled', true);

        const formElement = document.querySelector('#Login-form');
        const formData = new FormData(formElement);
        const formDataJSON = Object.fromEntries(formData);

        await registerUser(formDataJSON, locationState?.from);

        submitButton.removeAttribute('disabled');
    }

    return (
        <main>
            <div className='Signin'>
                <h2 className="Center-text">Sign Up</h2>
                <div className="Separator"></div>
                <form id="Login-form" onSubmit={submitSignupForm}>
                    <p>Use your Queen's email here, its how we make sure you're really a student.</p>
                    <input className="Input-box" type="email" name="email" placeholder="Email @queensu.ca" autoComplete="email" required/>
                    <input className="Input-box" type="text" name="userName" placeholder="Username" autoComplete="username" required/>
                    <div className="Input-row Password-container">
                        <input className="Input-box" id="Password" type="password" name="password" placeholder="Password" autoComplete="new-password" required/>
                        <button className="Password-reveal" type="button" onClick={(e) => {revealPassword(e, "Password", setVisible)}} title="Show password"><RevealIcon active={visible}/></button>
                    </div>
                    <div className="Input-row Password-container">
                        <input className="Input-box" id="Confirm-password" type="password" name="confirmPassword" placeholder="Confirm password" autoComplete="new-password" required/>
                        <button className="Password-reveal" type="button" onClick={(e) => {revealPassword(e, "Confirm-password", setConfirmVisible)}} title="Show password"><RevealIcon active={confirmVisible}/></button>
                    </div>
                    <br/>
                    <p>We need to know a few others things to get you started, but don't worry, you always have the option to change it later.</p>
                    <div className="Input-row">
                        <input className="Input-box" type="text" name="firstName" placeholder="First name" autoComplete="given-name" required/>
                        <input className="Input-box" type="text" name="lastName" placeholder="Last name" autoComplete="family-name" required/>
                    </div>

                    <div className="Input-row">
                        <select className="Input-box" id="Program-input" name="program">
                            <option value="select">Program</option>
                            {programs.map((item) => { return <option value={item.program}>{item.name}</option>; })}
                        </select>
                        <select className="Input-box" name="gradDate">
                            <option value="select">Grad year</option>
                            {getGradYears().map((year) => { return <option value={year}>{year}</option>; })}
                        </select>
                    </div>

                    <label className="Checkbox-label">
                        <input className="Stay-signed-in" type="checkbox" name="stayLoggedIn"/>
                        Stay signed in
                    </label>
                    <Link to="/login" className="Link" state={locationState}>Already have an account? Log in here instead</Link>
                    <CtaButton type="submit" id="Signup-button" text="Sign Up" icon={<ArrowForwardRounded/>} />
                    <div className="Signin-error">
                        {error && <Error error={error}/>}
                    </div>
                </form>

            </div>
        </main>);
}