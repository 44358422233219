import classNames from "classnames";
import "./CtaButton.css"

// Functions as a regular button but with standardized styling
export default function CtaButton({ text, icon, outlined, small, buttonRef, ...rest }) {

    const classes = classNames("Cta-button", {
        Outlined: outlined,
        Small: small
    });

    return (
        <button {...rest} ref={buttonRef} className={classes}>
            <p className="Cta-button-text">{text}</p> {icon}
        </button>
    );
}