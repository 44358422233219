import { useEffect, useRef, useState } from "react";
import "./DropdownController.css"

// Simple wrapper providing open / close functionality
// The user of this component is responsible for styling it to float above other elements
export default function DropdownController({ button, dropdown, className}) {

    const [open, setOpen] = useState(false);

    const buttonRef = useRef();

    useEffect(() => {
        let handler = (e) => {
            // The button already handles closing when it is clicked
            if (!buttonRef.current.contains(e.target)) {
                setOpen(false);
            }
        };

        document.addEventListener("click", handler);


        return () => {
            document.removeEventListener("click", handler);
        };
    });

    return (
        <div className={"Dropdown-root " + className} >
            <div onClick={() => { setOpen(!open) }} ref={buttonRef} className="Dropdown-button">
                {button}
            </div>

            <div className={"Dropdown-menu " + (!open ? "Closed" : "") /* Closed class helps with animating opening/closing */}>
                {dropdown}
            </div>
        </div>
    );
} 