import classNames from "classnames";
import "./Avatar.css"

const API_URL = process.env.REACT_APP_API_URL;
const transparentImage = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"

/**
 * Displays a user's avatar
 * 
 * Uses the user's avatar color, and can display a loading
 * shimmer effect while the information is loading. The size 
 * is set by passing a css class and setting the dimensions.
 */
export default function Avatar({ userId, isLoading, className, imageRef, ...rest }) {
    // Display a shimmer animation while loading
    // (Also display loading shimmer if user is falsy or empty
    //  making `isLoading` above technically optional)
    isLoading = isLoading || !userId
    const classes = classNames("Avatar", {
        Loading: isLoading
    });

    const image = !userId ? transparentImage : API_URL + "/images/useravatar/" + userId + ".png";

    return (
        <div className={"Z-index-buffer " + (className ?? "")} {...rest}>
            <div className="Avatar-outer">
                <img className={classes} src={image} onError={(error) => {error.target.src = "/placeholder-small.svg"}} alt="" ref={imageRef} />
            </div>
        </div>
    );
}