import { useState, useEffect } from "react";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export default function useFindUser() {
    const [user, setUser] = useState(null);
    const [favForums, setFavForums] = useState([]);
    const [isLoading, setLoading] = useState(true);

    async function refreshUser() {
        setLoading(true);
        await axios.get(API_URL + "/user/checkUser").then(res => {
            setUser(res.data.user);
            if (res.data.user != null) {
                console.log("User already signed in");
                setFavForums(res.data.user.forumFavs);
            }
            else { console.log("User not signed in"); }
            setLoading(false);
        }).catch(error => {
            console.log(error);
            setLoading(false);
        });
    }

    async function refreshFavForums() {
        // Get a list of the user's joined forums, to make a list of links
        axios.get(API_URL + "/user/userFavForums").then((res) => {
            setFavForums(res.data.data.forumFavs || []);
        }).catch((err) => {
            console.log(err);
        })
    }

    useEffect(() => {
        refreshUser();
    }, []);

    return {
        user,
        setUser,
        refreshUser,
        isLoading,
        favForums,
        setFavForums,
        refreshFavForums
    };
}