import '../App.css';
import FeedPost from '../components/FeedPost/FeedPost'
import CtaButton from '../components/CtaButton/CtaButton';
import { useNavigate } from 'react-router-dom';
import { AddRounded } from '@mui/icons-material';
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

function Home() {
    const navigate = useNavigate();

    // Find a way to do this without rerendering the whole page
    const [ postArray, setPostArray ] = useState(null);

    const lastTimestamp = useRef(undefined);
    const loadingNextPage = useRef(true); // Initially True while loading first page
    const [ reachedEnd, setReachedEnd ] = useState(false);

    useEffect(() => {
        const getNextPosts = async () => {
            loadingNextPage.current = true;

            try {
                const response = await axios.get(API_URL + "/post/", { params: {start: lastTimestamp.current} });
                console.log(response.data);
                if (response.data.length === 0) {
                    setReachedEnd(true);
                }
                else {
                    lastTimestamp.current = response.data[response.data.length-1].createdAt;
                    setPostArray(posts => posts.concat(response.data));
                }
            }
            catch (error) {
                console.log(error);
            }
            loadingNextPage.current = false;
        }

        const handleScroll = (event) => {
            const offsetHeight = document.documentElement.offsetHeight;
            const innerHeight = window.innerHeight;
            const scrollTop = document.documentElement.scrollTop;

            const hasReachedBottom = offsetHeight - (innerHeight + scrollTop) <= 300;

            if (hasReachedBottom && !loadingNextPage.current && !reachedEnd) {
                console.log("Fetching next posts");
                getNextPosts();
            }
        }

        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll)
    }, [reachedEnd]);

    useEffect(() => {
        // Get every post on the website
        axios.get(API_URL + "/post/").then((response) => {
            lastTimestamp.current = response.data[response.data.length-1].createdAt;
            loadingNextPage.current = false;
            setPostArray(response.data);
        }).catch((err) => {
            console.log(err);
            setPostArray([]);
        });
    }, []);

    var posts = postArray?.map(function(post){
        return <FeedPost userId={post.userID} post={post} forumId={post.forumID} key={post._id} />;
    });

    return (
        <main>
            <div className='Feed'>
                <div className='Feed-header'>
                    <button className='Feed-header-button Selected' onClick={() => {/* Set sorting method, refreshes feed */}}>Latest</button>
                    <button className='Feed-header-button' disabled={true} onClick={() => {/* Set sorting method, refreshes feed */}}>Popular</button>
                    <div className='Spacer'/>
                    <CtaButton onClick={() => {navigate('/createPost')}} text="Create Post" icon={<AddRounded/>}/>
                </div>

                <div className="Posts-container">
                    { postArray ?
                        posts
                        : null
                    }
                    { (postArray && postArray.length === 0) ? <><br/><p className="Center-text">Something went wrong :( <br/>Please try again later</p></> : null }
                    { reachedEnd && <h3 className="End-notice">You've reached the end!</h3>}
                </div>

            </div>
            <div className='Sidebar'>
                <div className="Sidebar-banner-container">
                    <img className="Sidebar-banner" src="/VirtualExpHomepage.png" alt=""/>
                </div>
                <h2>Home</h2>
                <div className='Separator'/>
                <p>The latest content from all of your forums in one place.</p>
                <p>Currently, this shows posts from every forum on the site.</p>

            </div>
        </main>
    );
}

export default Home;