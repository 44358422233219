import { ArrowForwardRounded } from "@mui/icons-material";
import CtaButton from "./../components/CtaButton/CtaButton";
import { useState, useEffect } from "react"
import axios from "axios";
import "./createpost.css";
import { useNavigate, useSearchParams } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;

function Error(props) {
  return(
      <div className="Login-error">
          <h5>{props.error}</h5>
      </div>
  );
}

export default function CreatePost(props) {

  const [forumList, setForumList] = useState();
  const [currForum, setCurrForum] = useState(0);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    axios.get(API_URL + "/forum/").then((res) => {
      const list = res.data; // Can't access new value in forumList until after useEffect completes
      setForumList(list);

      // If possible, autoselect the forum the user clicked the button in
      const index = list.findIndex(forum => { return forum._id === searchParams.get("forum")});
      if (index > -1) {
        setCurrForum(index);
        // Update the dropdown to show the correct value
        const dropdown = document.getElementById("forumsDropdown");
        dropdown.value = index;
      }
      else {
        console.log("Cant find forum")
        setCurrForum(0);
      }
    })
  }, [searchParams])

  const handleForumChange = (e) => {
    e.preventDefault();
    setCurrForum(e.target.value);
  }

  const handleCreatePost = (e) => {
    e.preventDefault();

    const submitButton = document.querySelector('#Create-post-button');
    submitButton.setAttribute('disabled', true);

    const formElement = document.querySelector('#Create-post-form');
    const formData = new FormData(formElement);
    let formDataJSON = Object.fromEntries(formData);
    formDataJSON = {
      ...formDataJSON,
      forumID: forumList[currForum]._id,
      userID: props.user._id
    }

    axios.post(API_URL + "/post", formDataJSON).then((res) => {
      // Bring the user to the page for their new post
      navigate("/post/" + res.data.data._id);
    }).catch((e) => {
      console.log(e);
      setError(e?.response?.data.message || "Problem connecting to server");
      submitButton.removeAttribute("disabled");
    })
  }

  return (<main>
      <div id = "CreatePostFeed" className="Feed">
        <div className="Feed-header" id = "CreatePost">
          <h2 className="CreatePostHeader">Posting to</h2>
          <select className="Input-box forumsDropdown" id="forumsDropdown" name="forum" onChange={handleForumChange}>
              {forumList ? forumList.map((item, i) => { return <option className = "forum-option" value={i}>{item.name}</option>}) : <option>Loading...</option> }
          </select>
        </div>
        <div className="Create-post-div">
          <form id="Create-post-form" onSubmit={handleCreatePost}>
            <input type = "text" className="Input-box Create-post-header" placeholder = "Post title..." name = "title" onChange={() => {setError(null)}}/>
            <textarea type="text" className="Input-box Create-post-body" placeholder = "Write your post here!" name = "content" onChange={() => {setError(null)}}/>
            <CtaButton type="submit" id="Create-post-button" text="Create Thread" icon={<ArrowForwardRounded />} />
            {error && <Error error={error}/>}
          </form>
        </div>

      </div>
      <div className="Sidebar">
        { forumList ? // Don't show the sidebar contents until the user data is fetched
          <>
            <div className="Sidebar-banner-container">
                <img className="Sidebar-banner" src={forumList[currForum].forumImage} alt="" onError={(error) => {error.target.src = "/placeholder-large.svg"}}/>
            </div>
            <h2>{forumList[currForum].name}</h2>
            <div className="Separator"></div>
            <p>{forumList[currForum].bio}</p>
          </>
          :
          <>
              <div className="Sidebar-banner-container">
                  <div className="Sidebar-banner Loading"/>
              </div>
              <div className="Loading-header"/>
          </>
          }
      </div>
  </main>);
}