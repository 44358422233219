import { ArrowForwardRounded } from "@mui/icons-material";
import CtaButton from "./../components/CtaButton/CtaButton";
import axios from "axios";
import "./CreateForum.css";
import { useNavigate } from 'react-router-dom';
import { useState } from "react";

const API_URL = process.env.REACT_APP_API_URL;

function Error(props) {
    return(
        <div className="Login-error">
            <h5>{props.error}</h5>
        </div>
    );
}

export default function CreateForum() {

    const navigate = useNavigate();
    const [error, setError] = useState(null);

    const handleCreateForum = (e) => {
        e.preventDefault();

        const submitButton = document.querySelector('#Create-forum-button');
        submitButton.setAttribute('disabled', true);

        const formElement = document.querySelector('#Create-forum-form');
        const formData = new FormData(formElement);
        let formDataJSON = Object.fromEntries(formData);

        console.log(JSON.stringify(formDataJSON))

        axios.post(API_URL + "/forum", formDataJSON).then((res) => {
            console.log(res.data.data._id)
            navigate("/forum/" + res.data.data._id)
        }).catch((e) => {
            console.log(e);
            setError(e?.response?.data.message || "Problem connecting to server");
            submitButton.removeAttribute("disabled");
        })
    }

    const updateName = () => {
        const input = document.querySelector(".Create-forum-name");
        const preview = document.getElementById("Name-preview");

        setError(null); // Clear error message once they start changing things
        preview.innerText = input.value || "Forum Name";
    }

    const updateBio = () => {
        const input = document.querySelector(".Create-forum-bio");
        const preview = document.getElementById("Bio-preview");

        setError(null);
        preview.innerText = input.value || "Forum description";
    }

    const updateImage = () => {
        const input = document.querySelector(".Create-forum-image");
        const preview = document.getElementById("Image-preview");

        setError(null);
        preview.src = input.value || "https://placekitten.com/800/500";
    }

    return (<main>
        <div className="Feed">
            <div className="Feed-header Create-forum-header">
                <h2 className="Create-forum-title">Creating a Forum</h2>
            </div>
            <div className="Create-forum-div">
                <form id="Create-forum-form" onSubmit={handleCreateForum}>
                    <input type="text" className="Input-box Create-forum-name" placeholder="Forum Name" name="name" onChange={updateName} />
                    <input type="url" className="Input-box Create-forum-image" placeholder="Banner Url" name="forumImage" onChange={updateImage} />
                    <textarea type="text" className="Input-box Create-forum-bio" placeholder="Forum description" name="bio" onChange={updateBio} />
                    <CtaButton type="submit" id="Create-forum-button" text="Create Forum" icon={<ArrowForwardRounded />} />
                    {error && <Error error={error}/>}
                </form>
            </div>
        </div>

        <div className="Sidebar">
            <div className="Sidebar-banner-container">
                <img className="Sidebar-banner" id="Image-preview" src="https://placekitten.com/800/500" alt="" onError={(error) => {error.target.src = "/placeholder-large.svg"}}/>
            </div>
            <h2 id="Name-preview">Forum Name</h2>
            <div className="Separator"></div>
            <p id="Bio-preview">Forum description</p>
        </div>
    </main>);
}